import $ from 'jquery';
window.$ = window.jQuery = $;

import 'bootstrap';
import 'howler';
import './global/toast';
import './global/matchHeight';
import '../scss/main.scss';
import images from './import_images';

$(document).ready(function() {

	var notice = $('.flex-notice');
	var notice_height = notice.outerHeight();
	//var notice_container = $('.notice-container');

	$('.content-wrapper').css('padding-top', notice_height);

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 1) {
			notice.addClass('scrolled');
			//notice_container.addClass('fixed');
		} else {
			notice.removeClass('scrolled');
			//notice_container.removeClass('fixed');
		}
	});

	var available = {};
	var init = { region: null };

	var timespan = $('[data-clock]');
	var current_hour = new Date().getHours();

	var menu_opt = {
		hide_unavailable: 0
		//last_chance: 0,
		//new_arrivals: 0
	}

	function matchHeight(selector) {
		const elements = document.querySelectorAll(selector);
		let maxHeight = 0;

		// Reset the height to auto to get the original heights
		elements.forEach(element => {
			element.style.height = 'auto';
		});

		// Calculate the maximum height
		elements.forEach(element => {
			const height = element.offsetHeight;
			if (height > maxHeight) {
				maxHeight = height;
			}
		});

		// Set the height for all elements to the maximum height
		elements.forEach(element => {
			element.style.height = maxHeight + 'px';
		});
	}

	function setRegion(new_region) {
		localStorage.setItem('critter_region', new_region);
		init.region = new_region;
		//console.log('new region: '+localStorage.critter_region);
		fetchDynamicCritters(init.region);
		$('[data-region-display]').html(init.region);
	}

	if (localStorage.critter_region !== undefined) {
		init.region = localStorage.critter_region;
		$('[data-region-display]').html(init.region);
		updateDisplayMonths();
		//console.log('stored region: ' + localStorage.critter_region);
	} else {
		init.region = 'northern';
		$('[data-region-display]').html(init.region);
		updateDisplayMonths();
	}

	function updateDisplayMonths() {

		//console.log('updated months');
		if (init.region !== 'northern') {
			$('.southern-months').removeClass('d-none');
			$('.northern-months').addClass('d-none');
		} else {
			$('.northern-months').removeClass('d-none');
			$('.southern-months').addClass('d-none');
		}

	}

	$(document).on('click', '[data-region]', function() {

		if (init.region !== 'northern') {
			setRegion('northern');
		} else {
			setRegion('southern');
		}

	})

	//console.log('init.region: ' + init.region);
	$('[data-region]').addClass('init');

	$.fn.classDelay = function(action, class_name, delay) {
		var $classDelayElement = $(this);
		//$classDelayElement.addClass(class_name);
		setTimeout(function() {
			if (action == 'add') {
				$classDelayElement.addClass(class_name);
			} else if (action == 'remove') {
				$classDelayElement.removeClass(class_name);
			}
		}, delay);
	};

	$('[data-loader]').classDelay('add', 'loaded', 250);

	function delay(callback, ms) {
		var timer = 0;
		return function() {
			var context = this,
				args = arguments;
			clearTimeout(timer);
			timer = setTimeout(function() {
				callback.apply(context, args);
			}, ms || 0);
		};
	}

	/* $('#table').bootstrapTable({
		searchTimeOut: 250,
		//url: 'data.json',
		pagination: false,
		search: true,
		trimOnSearch: false,
		cardView: false
	}); */

	function updateMH(delay) {
		setTimeout(function() {
			$.fn.matchHeight._update();
			console.log('matchHeight: triggered');
		}, delay);
	}

	//$.fn.matchHeight._maintainScroll = true;
	//$.fn.matchHeight._throttle = 250;

	function fetchDynamicCritters(region) {

		$('[data-loader]').classDelay('remove', 'loaded', 0);
		$('.critter-container').classDelay('remove', 'in-view', 0);

		$('.filter').val('').keyup().blur();
		var body_class = $('body').attr('class');

		$('[data-fetch-container]').load('fetch/_' + body_class, { 'region': region }, function() {
			updateAvailableCritters(current_hour);
			updateDisplayMonths();
			$('[data-loader]').classDelay('add', 'loaded', 250);
			$('.critter-container').classDelay('add', 'in-view', 750);
			//console.log(body_class+' '+region);
		});

	}

	fetchDynamicCritters(init.region);

	var KEYCODE_ENTER = 13;
	var KEYCODE_ESC = 27;

	$(document).keyup(function(e) {
		if (e.keyCode == KEYCODE_ESC && $('.offcanvas-collapse').hasClass('open')) {
			$('.navbar-toggler').click();
		} else if (e.keyCode == KEYCODE_ESC && !$('.offcanvas-collapse').hasClass('open') && !$('.filter').is(':focus')) {
			$('.navbar-toggler').click();
		} else if (e.keyCode == KEYCODE_ESC && $('.filter').is(':focus')) {
			$('.card-critter-col').removeClass('d-none');
			$('.filter').val('').keyup().blur();
			updateMH(300);
		} else if (e.keyCode == KEYCODE_ENTER && $('[data-cta-email]').is(':focus')) {
			$('[data-submit="cm-subscribe"]').click();
		}
	});

	$(document).on('keydown', 'form', function(e) {
		return e.key != 'Enter';
	});

	$(document).keypress(function(e) {
		e = e || window.event;
		var charCode = e.which || e.keyCode;
		var charStr = String.fromCharCode(charCode);
		if (/[a-z0-9#]/i.test(charStr) && !$('[data-cta-email]').is(':focus')) {
			$('.filter').focus();
			$('.critter-container').removeClass('menu-focus');
		}
	});

	time();

	function time() {
		var d = new Date();
		var m = d.getMinutes();
		var h = d.getHours();
		if (h != current_hour) {
			showToast();
			updateAvailableCritters(h);
		}
		var hh = h;
		current_hour = h;
		var meridiem = h >= 12 ? 'pm' : 'am';
		m = m < 10 ? '0' + m : m;
		hh = ((hh + 11) % 12 + 1);
		var current_time = hh + ":" + m + meridiem;
		timespan.html(current_time);
		//console.log('Current 24H: ' + current_hour);
	}
	setInterval(time, 1000);

	function updateAvailableCritters(current_hour) {

		$('.card.critter').attr('data-now', 'no');

		//console.log('updateAvailableCritters: ' + current_hour + ' / ' + Math.floor((Math.random() * 10000) + 1));

		var date = new Date();
		var current_month = date.toLocaleString('default', { month: 'long' }).toLowerCase();

		available.bug = 0;
		available.fish = 0;
		available.sea = 0;

		$('[data-acnh]').each(function() {
			var critter_type = $(this).data('type');
			var hours = $(this).data('hours');
			var hours_array = hours.split(' ').map(Number);

			var months = $(this).data(init.region);
			var months_array = months.split(' ');

			//console.log(current_month+' '+current_hour);
			if (months_array.includes(current_month, 0)) {
				if (hours_array.includes(current_hour, 0)) {
					var req_uid = $(this).attr('data-req-uid');
					var display_uid = '[data-display-uid="' + req_uid + '"]';
					$(display_uid).attr('data-now', 'yes');
					available[critter_type]++;
					//console.log(req_uid +' '+display_uid);
				}
			}
		})

		updateCritterDisplay();

		var fish_perc = Math.round(available.fish / 80 * 100);
		var bug_perc = Math.round(available.bug / 80 * 100);
		var sea_perc = Math.round(available.sea / 40 * 100);

		$('[data-availperc-text-fish]').html(available.fish);
		$('[data-availperc-text-bugs]').html(available.bug);
		$('[data-availperc-text-sea]').html(available.sea);

		$('[data-availperc-fish]').css('width', fish_perc + '%').attr('aria-valuenow', fish_perc);
		$('[data-availperc-bugs]').css('width', bug_perc + '%').attr('aria-valuenow', bug_perc);
		$('[data-availperc-sea]').css('width', sea_perc + '%').attr('aria-valuenow', sea_perc);

		//console.log('Bugs available: ' + available.bug);
		//console.log('Fish available: ' + available.fish);
		//console.log('Sea Creatures available: ' + available.sea);
	}

	$(document).on('click', '[data-toggle="offcanvas"]', function() {
		setTimeout(function() {
			updateAvailableCritters(current_hour);
		}, 300);
		$('.offcanvas-collapse').toggleClass('open');
		if ($('.offcanvas-collapse').hasClass('open')) {
			$('.icon').addClass('search-muted');
			$('.critter-container').addClass('menu-focus');
			$('.navbar-toggler-icon').addClass('nav-icon-muted');
		} else {
			$('.icon').removeClass('search-muted');
			$('.critter-container').removeClass('menu-focus');
			$('.navbar-toggler-icon').removeClass('nav-icon-muted');
		}
	})

	$(document).on('click', '.container-fluid', function() {
		if ($('.critter-container').hasClass('menu-focus')) {
			$('.navbar-toggler').click();
		}
	})

	$('.filter').on('focus', function() {
		if ($('.offcanvas-collapse').hasClass('open')) {
			$('.critter-container').removeClass('menu-focus');
			$('.icon').removeClass('search-muted');
			$('.offcanvas-collapse').removeClass('open');
		}
	})

	$('.card.critter').matchHeight({ property: 'min-height' });

	$('.filter').keyup(delay(function(e) {
		//console.log('keyup delay');
		if ($(this).val().length >= 1) {
			$('[data-clear-filter]').addClass('in-view');
		} else {
			$('[data-clear-filter]').removeClass('in-view');
		}
		var input = $(this).val().toUpperCase().replace(/[^a-zA-Z 0-9#]+/g, '');
		var i = 0;
		$('.card.critter').each(function() {
			if ($(this).data('string').toUpperCase().indexOf(input) < 0 && $(this).data('northern').toUpperCase().indexOf(input) < 0 && $(this).data('southern').toUpperCase().indexOf(input) < 0) {
				$(this).parent().addClass('d-none');
			} else {
				$(this).parent().removeClass('d-none');
				i++;
			}
			//console.log(i);

			checkVisibility();
		})
		$('html, body').scrollTop(0);
	}, 250));

	$(document).on('click', '[data-clear-filter]', function() {
		$('.filter').val('').keyup().focus();
	})

	$(document).on('click', '[data-toggle-sw]', function() {
		if ($(this).is(':checked')) {
			menu_opt.hide_unavailable = 1;
			switch_on.play();
		} else {
			menu_opt.hide_unavailable = 0;
			switch_off.play();
		}
		updateCritterDisplay();
	})

	function updateCritterDisplay() {

		if (menu_opt.hide_unavailable == 1) {
			$('[data-now="no"]').parent().addClass('hidden-unavailable');
			$('[data-now="yes"]').parent().removeClass('hidden-unavailable');
		} else {
			//$('[data-now="no"]').parent().removeClass('hidden-unavailable');
			$('[data-now]').parent().removeClass('hidden-unavailable');
		}

		checkVisibility();
		updateMH(300);

	}

	function checkVisibility() {

		if ($('.card.critter:visible').length == 0) {
			$('.no-results').addClass('d-block').removeClass('d-none');
			//$('.notice').addClass('d-none').removeClass('d-block');
		} else {
			$('.no-results').addClass('d-none').removeClass('d-block');
			//$('.notice').addClass('d-block').removeClass('d-none');
		}

	}

	$(document).on('click', '[data-notification]', function() {

		$('[data-notification]').toast('hide');

	})

	var switch_news = new Howl({
		src: ['../audio/switch-news.mp3']
	});

	var switch_on = new Howl({
		src: ['../audio/switch-on.mp3']
	});

	var switch_off = new Howl({
		src: ['../audio/switch-off.mp3']
	});

	function showToast() {

		$('[data-notification]').toast('show');
		switch_news.play();

	}

	$(document).on('click', '[data-submit="cm-subscribe"]', function(e) {

		e.preventDefault();

		$('#cm-email').blur().addClass('disabled');
		$('[data-submit]').addClass('disabled');

		$.ajax({
			type: 'POST',
			url: '/cm-subscribe/_subscribe.php',
			data: {
				email: $('#cm-email').val()
			},
			dataType: 'json',
			success: function(data) {

				$('[data-feedback]').html(data.verbose).removeClass('d-none');

				$('#cm-email').val('').removeClass('disabled');
				$('[data-submit]').removeClass('disabled');

			}

		})

	})

	/* var chart_options = {
		chart: {
			renderTo: 'charthours',
			type: 'spline',
			//animation: Highcharts.svg,
			backgroundColor: null,
			margin: [0, 5, 0, 5],
			events: {
				//
			}
		},
		title: {
			text: ' '
		},
		xAxis: {
			reversed: false,
			lineWidth: 0,
			minorGridLineWidth: 0,
			lineColor: 'rgba(0,0,0,1)',
			labels: {
				enabled: true,
				style: {
					color: 'rgba(97,203,152,1)'
				}
			},
			minorTickLength: 2,
			tickLength: 6
		},
		yAxis: {
			gridLineColor: 'rgba(0,0,0,0)',
			labels: {
				enabled: false
			},
			title: {
				text: ' '
			},
			plotLines: [{
				value: 0,
				width: 0,
				color: 'rgba(0,0,0,0)'
			}]
		},
		tooltip: {
			shared: true,
			enabled: false
		},
		legend: {
			enabled: false
		},
		exporting: {
			enabled: false
		},
		credits: {
			enabled: false
		},
		plotOptions: {
			column: {
				stacking: 'normal'
			},
			spline: {
				lineWidth: 3
			},
			series: {
				//animation: {
				//	duration: 1300
				//},
				borderWidth: 0,
				color: 'rgba(97,203,152,1)',
				marker: {
					enabled: false,
				},
				states: {
					hover: {
						enabled: false,
						lineWidthPlus: 0
					}
				}
			}
		},
		//series: []
		series: [{
			name: 'Fish',
			data: [
				3.7, 3.3, 3.9, 5.1, 3.5, 3.8, 4.0, 5.0, 6.1, 3.7, 3.3, 6.4
			]
		}, {
			name: 'Bugs',
			data: [
				4.7, 4.3, 4.9, 6.1, 4.5, 3.8, 4.7, 5.0, 2.1, 4.7, 3.3, 8.4
			]
		}]
	}

	var chart = new Highcharts.Chart(chart_options); */


});